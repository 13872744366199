#sidebar {
  background: #f1f1f1;
  display: none;
  overflow: auto;
  padding: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100%;
}

#sidebar .separator {
  font-size: 14px;
  padding: 5px 0;
  color: #633ccda1;
  font-weight: bold;
  border-bottom: 1px solid #633ccd;
  margin-bottom: 10px;
}

#sidebar_close_icon {
  color: #633ccd;
  position: absolute;
  right: 8px;
  font-size: 20px;
  z-index: inherit;
  /* display: inline; */
  cursor: pointer;
}

.sidebar_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #633ccd;
  margin-bottom: 10px;
  padding: 5px 20px;
  padding-top: 10px;
}

.sidebar-header-image {
  width: 80px;
  height: 80px;
  border-radius: 160px;
}

.sidebar_link {
  color: #633ccd;
  padding: 11px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
}

.sidebar_link:hover {
  background: #633ccd;
  border-radius: 15px;
  color: #ffffff !important;
}

.active_menu_link {
  background: #633ccd;
  border-radius: 15px;
}

.sidebar_link_text {
  text-decoration: none;
  color: #633ccd;
}

.active_menu_link .sidebar_link_text,
.active_menu_link .sidebar_link_icon {
  color: #ffffff !important;
}

.sidebar_link_icon {
  margin-right: 15px;
  font-size: 14px;
  color: #633ccd;
}

.sidebar_link:hover .sidebar_link_icon,
.sidebar_link:hover .sidebar_link_text {
  color: #ffffff;
}

.sidebar_menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: 90%;
  padding: 0 20px;
}

.sidebar_logout {
  margin-top: 30px;
  padding: 10px;
  color: #e65061;
  display: flex;
  cursor: pointer;
}

.sidebar_logout_text {
  text-decoration: none;
  color: #e65061;
  font-size: 14px;
}

.sidebar_logout:hover .sidebar_logout_text {
  color: #ffffff;
}

.sidebar_logout:hover {
  background: #e65061;
  border-radius: 15px;
}

.sidebar_logout_icon {
  margin-right: 12px;
  color: #e65061;
}

.sidebar_logout:hover .sidebar_logout_icon,
.sidebar_logout:hover .sidebar_link_text {
  color: #ffffff;
}

.sidebar_responsive {
  display: inline !important;
  position: absolute;
  height: 100vh;
}

.coming-soon {
  font-size: 9px;
  color: red;
}

.sidebar-image-container {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 769px) {
  #sidebar {
    width: 20%;
    display: block;
  }

  #sidebar_close_icon {
    display: none;
  }

  .sidebar-image-container {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 900px) {
  #sidebar {
    width: 18%;
    display: block;
  }
}
