#main_left_box {
  width: 100%;
}

.dashboard_container {
  display: flex;
  flex-direction: column;
}

#main_right_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top_stat_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
}

.card_adjust_stat {
  width: 48%;
  padding-left: 5%;
  padding-right: 5%;
}

.chart_box {
  width: 100%;
  padding-top: 10px;
}

.inner_chart_box {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 10px;
}

#chart_box1 {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 30px;
}

.student_list_box {
  width: 98%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 30px;
}

#chart_box2 {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 30px;
}

.main_right_boxes {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.card_adjust {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.barchart {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

@media (min-width: 769px) and (max-width: 884px) {
  .dashboard_container {
    display: flex;
    flex-direction: column;
  }

  .top_stat_box {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
  }

  .card_adjust_stat {
    flex: 1 1 45%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 885px) {
  .dashboard_container {
    display: flex;
    flex-direction: row;
  }

  .top_stat_box {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
  }

  .card_adjust {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .barchart {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }

  #chart_box1 {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }

  #chart_box2 {
    padding-left: 3%;
    padding-right: 3%;
  }

  .card_adjust_stat {
    width: 49%;
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (min-width: 900px) {
  #main_left_box {
    width: 72%;
  }

  #main_right_box {
    width: 28%;
  }

  .barchart {
    width: 98%;
  }

  .top_stat_box {
    gap: 2%;
    flex-wrap: nowrap;
  }

  .card_adjust_stat {
    flex-direction: column;
    width: 23%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .inner_chart_box {
    flex-direction: row;
    gap: 2%;
  }

  #chart_box1,
  #chart_box2 {
    width: 48%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .card_adjust {
    width: 98%;
    padding-left: 6%;
    padding-right: 6%;
  }

  main {
    padding-right: 1.6%;
  }
}
