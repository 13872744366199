.main_header {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.left_header_title {
  margin-bottom: 20px;
  display: flex;
  font-size: 16px;
  align-items: flex-start;
  flex: 1;
}

.main_header_icon {
  font-size: 20px;
  margin-right: 8px;
}

.main_header_text {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.header_button {
  margin-top: 0px;
}
