.item-card {
  border-radius: 7px;
  box-shadow: 0px 2px 5px 0px rgba(145, 134, 134, 0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(145, 134, 134, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(145, 134, 134, 0.75);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  flex-wrap: wrap;
  position: relative;
}

.item-card .image-container {
  width: 100%;
  text-align: center;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f1f1f1;
}

.item-card .image-container img {
  width: 100%;
  height: 100%;
}

.item-card .details {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 5px;
}
