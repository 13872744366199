.display-report {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}

.report-sheet-display {
  display: flex;
  justify-content: center;
  width: 700px;
}

.report-body {
  height: auto;
  width: 700px;
  border: 5px solid #000000;
  padding: 15px;
  font-size: 6.5px;
}

.report-body p,
.report-detail-group p {
  margin: 3px;
}

.report-title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}

.report-subtitle {
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-top: 3px;
}

.report-detail-group {
  display: flex;
  font-size: 12px;
  justify-content: center;
  column-gap: 20px;
}

.report-detail-group-item {
  display: flex;
  column-gap: 3px;
}

.report-detail-group-title {
  font-weight: bold;
}

.report-rating-box {
  margin-top: 5px;
  display: flex;
  column-gap: 5px;
}

.score-rating-table {
  border-collapse: collapse;
  flex: 1;
}

.score-rating-table th,
.score-rating-table td {
  border: 0.5px solid #000;
  padding: 8px;
  text-align: left;
}

.score-rating-table th {
  background-color: #f2f2f2;
}

.grade-rating-table {
  border-collapse: collapse;
  flex: 1;
}

.grade-rating-table th,
.grade-rating-table td {
  border: 0.5px solid #000;
  padding: 8px;
  margin: 0;
  text-align: center;
}

.grade-rating-table th {
  background-color: #f2f2f2;
}

.report-term-title {
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.report-sheet-area,
.transcript-box {
  margin-top: 5px;
  border-collapse: collapse;
  width: 100%;
}

.report-sheet-area th,
.report-sheet-area td,
.psycho-table th,
.psycho-table td,
.transcript-box th,
.transcript-box td {
  border: 0.5px solid #000;
  padding: 5px;
  text-align: left;
  min-width: 7px;
}

.transcript-box th,
.transcript-box td {
  font-size: 10px;
  padding: 5px;
}

.report-sheet-area th,
.psycho-table th,
.transcript-box th {
  background-color: #f2f2f2;
  text-align: center;
}

.report-sheet-area th[rowSpan="3"],
.report-sheet-area th[rowSpan="4"],
.report-sheet-area th[colSpan="3"],
.report-sheet-area th[colSpan="4"],
.transcript-box th[rowSpan="3"],
.transcript-box th[rowSpan="4"],
.transcript-box th[colSpan="3"],
.transcript-box th[colSpan="4"] {
  text-align: center;
}

.report-sheet-area td,
.psycho-table td,
.transcript-box td {
  text-align: center;
}

.report-sheet-area tr td:first-child,
.psycho-table tr td:first-child,
.transcript-box tr td:first-child {
  text-align: left;
  min-width: 5px;
}

.report-title-rotate {
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: middle bottom;
  white-space: nowrap;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 20px;
}

.report-summary-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 5px;
}

.report-summary-table td,
.report-summary-table th {
  border: 0.5px solid #000;
  padding: 4px;
  text-align: left;
}

.report-summary-table th {
  background-color: #f2f2f2;
}

.report-summary-table td + td {
  padding-left: 5px;
}

.report-summary-sheet {
  margin-top: 10px;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pyschomotor_box,
.affective_domain_box {
  width: 20%;
}

.rating_indicators {
  width: 10%;
}

.psycho-table {
  margin-top: 5px;
  border-collapse: collapse;
  width: 100%;
}

.psychomoto_form_box {
  display: flex;
  column-gap: 25%;
  flex-direction: column;
}

.psycho_area {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  margin-top: 10px;
}

.assessment_summary {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.student-assessment-details {
  width: 100%;
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}

.affective-domain {
  padding-right: 30px;
}

.psychomotor {
  padding-right: 50px;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .psychomoto_form_box {
    flex-direction: row;
  }

  .affective-domain {
    padding-right: 0;
  }

  .psychomotor {
    padding-right: 0;
  }

  .display-report {
    justify-content: center;
    overflow-x: hidden;
  }

  .report-sheet-display {
    width: 60%;
    overflow-x: hidden;
    justify-content: flex-start;
  }

  .report-body {
    width: 100%;
  }
}
