.list_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
  padding: 12px 3%;
  border-radius: 7px;
  cursor: pointer;
}

.list_item:hover {
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.35);
}

.list_item > img {
  width: 40px;
  margin-right: 4%;
}

.list_item > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.list_item_title {
  font-size: 14px;
  font-weight: 400;
}

.list_item_subtitle {
  font-size: 12px;
  font-weight: 300;
  color: #888686;
  margin-top: 5px;
}

.list_title_link {
  color: inherit;
  text-decoration: none;
}

.list_title_link:visited,
.list_title_link:active {
  color: inherit;
}

.side_box {
  width: 60px;
  padding: 0 2%;
  margin-right: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.date {
  font-size: 10px;
  color: #888686;
}

.time {
  font-size: 16px;
  color: #000000;
}
