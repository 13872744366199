.stat_card {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.stat_card_details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  column-gap: 15px;
}

.icon_container {
  width: 25%;
}

.stat_card_icon_box {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
}

.stat_card_side_details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 75%;
}

.stat_card_title {
  font-size: 13px;
  color: #888686;
  font-weight: 400;
}

.stat_card_icon {
  font-size: 10px;
  color: #ffffff;
}

.stat_card_entry {
  font-size: 20px;
  font-weight: 700;
}

.stat_card_underline {
  height: 3px;
  margin-top: 8px;
  border-radius: 3px;
}
