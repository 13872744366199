.attendance-type-box {
  width: 200px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  cursor: pointer;
  color: var(--primary);
  font-weight: bold;
}

.attendance-type-container {
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 30px;
  padding-top: 30px;
}

.qr-reader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.attendance-profile-box {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.attendance-profile-header {
  color: var(--primary);
  font-size: 18px;
}

.attendance-profile-avatar {
  margin: 20px 0;
}

.attendance-profile-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.attendance-profile-body p {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.attendance-profile-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--danger);
  margin-bottom: 10px;
  cursor: pointer;
}
.attendance-dashboard-filter {
  width: 100%;
  display: flex;
  column-gap: 8px;
}

/* Media Queries for mobile screens */
@media only screen and (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .attendance-dashboard-filter {
    width: 50%;
  }
}
