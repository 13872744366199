.tableCard {
  width: 100%;
  padding: 0;
}

.transcript-box {
  width: 100% !important;
  margin-bottom: 30px;
}

.transcript-student-details p {
  margin-bottom: 10px;
}

.transcript-student-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transcript-display-script {
  border: 2px solid #000000;
  padding: 30px;
  width: 100%;
  align-self: center;
}

.report-sheet-contents-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.transcript-container-script {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 60%;
}
