.settings-menu {
  width: 20%;
  padding-top: 0;
}

.settings-body {
  width: 100%;
}

.settings-menu-list {
  text-decoration: none;
  list-style: none;
}

.settings-menu-list li {
  padding: 15px 20px;
  border-bottom: 1px solid var(--primary);
  cursor: pointer;
}

.settings-menu-list li:hover {
  color: var(--primary) !important;
  font-weight: bold;
}

.school-profile-box {
  width: 100%;
}

.bio-data-item span {
  font-size: 14px;
}

.information-container {
  padding: 20px 10px;
}
