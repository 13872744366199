.datatable {
  width: 100%;
  z-index: 1;
}

.datatable-body {
  padding: 20px 2%;
}

.datatable-img-title {
  margin-left: 4%;
}

.datatable .p-paginator .p-paginator-current {
  margin-left: auto;
}
.datatable .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}
.datatable .p-progressbar .p-progressbar-value {
  background-color: #607d8b;
}
.datatable .p-datepicker {
  min-width: 25rem;
}
.datatable .p-datepicker td {
  font-weight: 400;
}
.datatable .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}
.datatable .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}
.datatable .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
  text-align: left;
}
.datatable .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
  cursor: auto;
}
.datatable
  .p-datatable.p-datatable-customers
  .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}
