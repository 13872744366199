@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

/* scrollbar */
/* colors */
/* 
  #633ccd
*/
:root {
  --primary: #633ccd;
  --secondary: #ffffff;
  --ash: #a5aaad;
  --danger: #ce1616;
  --black: #000000;
  --mute-text: #777;
}

.text-black {
  color: var(--black);
}

.text-danger {
  color: var(--danger) !important;
}

.mute-text {
  color: var(--mute-text);
}

.text-primary {
  color: var(--primary);
}

.bold {
  font-weight: bold;
}

/* custom */
.text-center {
  text-align: center;
}

/* font size */
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

/* margins */
.mt-m-10 {
  margin-top: -10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-60 {
  margin-right: 60px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-20 {
  padding-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.py-10 {
  padding: 10px 0;
}

.py-20 {
  padding: 20px 0;
}

.px-10 {
  padding: 0 10px;
}

.px-20 {
  padding: 0 20px;
}

.pl-40 {
  padding-left: 40px;
}

.my-20 {
  margin: 20px 0;
}

.w-100pc {
  width: 100%;
}

.w-20pc {
  width: 20% !important;
}

.w-25pc {
  width: 25% !important;
}

.w-50pc {
  width: 50% !important;
}

.md-w-50pc {
  width: 100% !important;
}

/* fonst size */
.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.bold {
  font-weight: bold;
}

.fs-16 {
  font-size: 16px !important;
}

/* display */
.block {
  display: block;
}

.visible-lg {
  display: none;
}

.visible-sm {
  display: flex;
}

.flex {
  display: flex;
}

/* hide */
.hide {
  display: none;
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start !important;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.row-g-8 {
  row-gap: 8px;
}

.row-g-12 {
  row-gap: 12px;
}

.col-g-8 {
  column-gap: 8px;
}

.row-g-20 {
  row-gap: 20px;
}

.row-g-8 {
  row-gap: 8px;
}

.col-g-12 {
  column-gap: 12px;
}

.col-g-14 {
  column-gap: 14px;
}

.col-g-16 {
  column-gap: 16px;
}

.col-g-18 {
  column-gap: 18px;
}

.space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.block {
  display: block;
}

.sm-block {
  display: block;
}

.sm-reverse-direction {
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--ash);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
}

::-wibkit-scrollbar-thumb:hover {
  background-color: var(--ash);
}

/* general */
.error-text {
  font-size: 14px;
  color: var(--danger);
}

.success-text {
  font-size: 14px;
  color: green;
}

.flex-center-top {
  display: flex;
  align-items: center;
}

.center-form-container {
  width: 60%;
  background-color: #f1f1f1;
  padding: 20px 35px;
  border-radius: 7px;
}

.form-container {
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px 15px;
  border-radius: 7px;
  margin-bottom: 50px;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  margin: 0;
}

.text-primary-p {
  color: var(--ash);
  font-size: 14px;
  font-weight: 700;
}

.container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  height: auto;
  padding-bottom: 30px;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  padding: 15px 5%;
}

.main-body-container {
  flex-direction: row;
}

.dashboard_card_button {
  width: 100px;
  height: 30px;
  font-size: 16px;
}

.report-subject {
  width: 70%;
}

/* modal */
.error_modal {
  position: absolute;
  bottom: 20;
  left: 20;
  height: 120;
  width: 330;
  padding: 20;
  background: #f1f1f1;
  border-radius: 7;
}

.p-progress-spinner-circle {
  stroke: #633ccd !important;
}

.button_spinner .p-progress-spinner-circle {
  stroke: #633ccd !important;
}

.custom-toast .p-toast {
  z-index: 999999;
}

/* custom badges */
.required {
  color: red;
  font-size: 14px;
}

.inner-unauthorized {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  flex-direction: column;
}

.inner-unauthorized p {
  font-size: 18px;
  margin-top: 10px;
}

.assessment_not_paid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.credit-nav-box {
  min-width: 100px;
  height: 30px;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  color: #633ccd;
}

.app-skeleton-container {
  min-width: 100%;
}

.app_input_group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.form-section-title {
  margin-top: 20px;
  font-size: 16px;
  color: #633ccd;
}

.form-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-avatar-container {
  padding-top: 30px;
}

.grade-setting-block {
  border: dotted 1px #633ccd;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  padding: 20px 15px;
  height: auto;
}

.grade-setting-block-content {
  flex-direction: column;
  display: flex;
  width: 100%;
}

/* avatar */
.avatar-preview-box {
  width: 200px;
  height: 200px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-preview-box img {
  width: 180px;
  height: 180px;
}

.asseessment_box {
  display: inherit;
  flex-wrap: wrap;
}

/* avatar */
.avatar-container {
  display: flex;
  background-color: #633ccd47;
  padding: 20px;
  border-radius: 12px;
  justify-content: space-between;
  column-gap: 30px;
  flex-direction: column;
}

.staff-avatar-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.personal-info-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
}

.profile-title {
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex: 1;
}

.personal-info-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;
  row-gap: 5px;
}

.profile-value {
  justify-content: flex-start;
  display: flex;
  flex: 1;
}

/* custom */
.sm-reverse-direction-vertical {
  display: flex;
  flex-direction: column;
}

.other-staff-info {
  padding: 20px;
  margin-top: -50px;
}

.other-staff-info-header {
  padding: 20px;
}

/* general components */
.floating-button-box {
  box-shadow: 0px 2px 5px 0px rgba(145, 134, 134, 0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(145, 134, 134, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(178, 97, 97, 0.75);
}

.simple-label {
  background-color: rgba(250, 7, 7, 0.28);
  padding: 5px 15px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: auto;
}

@media only screen and (min-width: 769px) {
  main {
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 40px 3%;
  }

  .visible-lg {
    display: flex;
  }

  .visible-sm {
    display: none;
  }

  .promotion-modal {
    width: 70%;
    z-index: 1000000;
  }

  .form-container {
    padding: 20px 25px;
  }

  .app_input_group {
    flex-direction: row;
    column-gap: 12px;
  }

  .md-w-50pc {
    width: 50% !important;
  }

  .grade-setting-block {
    flex-direction: row;
  }

  /* custom */
  .sm-reverse-direction-vertical {
    flex-direction: row;
  }
}

@media only screen and (min-width: 900px) {
  main {
    flex-direction: column;
    justify-content: center;
    margin-left: 18%;
    width: 82%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .form-container {
    padding: 20px 35px;
  }

  /* custom */
  .sm-block {
    display: flex;
  }

  .avatar-container {
    flex-direction: row;
    padding: 20px 40px;
  }

  .personal-info-block {
    flex-direction: column;
    margin-top: 30px;
  }

  .personal-info-list {
    flex-direction: column;
    column-gap: 80px;
    margin-bottom: 20px;
  }

  .staff-avatar-container {
    align-items: flex-start;
  }

  .other-staff-info {
    padding: 20px 40px;
    margin-top: -90px;
  }

  .other-staff-info-header {
    margin-top: 30px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .sm-100 {
    width: 100%;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  /* Show only the specific section */
  .report-sheet-display *,
  .transcript-container-script * {
    visibility: visible;
  }
  /* Ensure the print section is at the top of the page */
  .report-sheet-display {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    page-break-inside: avoid;
    background-color: white;
  }

  .transcript-container-script {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    border: 2px solid #000000;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    page-break-inside: avoid;
    background-color: white;
    margin-top: 150px;
  }
}
