.navbar {
  background-color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3%;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid #f1f1f1;
}

.navbar_left {
  display: none;
}

.nav_icon {
  margin-left: 10px;
}

.menu_bar {
  font-size: 25px;
  margin-top: 10px;
  cursor: pointer;
}

.navbar_left > a {
  margin-right: 20px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 14px;
}

.navbar_left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

.navbar_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_right > a {
  margin-left: 12px;
  text-decoration: none;
}

.navbar_right > a .icon_box {
  color: #302b2b;
  font-size: 16px;
  border-radius: 5px;
  background: #f1f1f1;
  height: 30px;
  padding: 7px;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navbar_right > a .icon_box > div {
  background: #e65061;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  right: 8px;
  border: 1px solid #ffffff;
}

.navbar_avatar_box {
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 10px;
  background: #f1f1f1f1;
  padding: 5px;
  color: #302b2b;
}

.navbar_avatar_container {
  position: relative;
  cursor: pointer;
}

.avatar_dropdown_menu {
  flex-direction: column;
  position: absolute;
  font-size: 14px;
  top: 30px;
  left: 10px;
  width: 140px;
  height: auto;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0;
}

.avatar_dropdown_menu ul {
  list-style: none;
}

.avatar_dropdown_menu ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

.avatar_settings_menu {
  margin: 10px 0;
}

.navbar_avatar_box_img {
  border-radius: 50px;
  margin-right: 8px;
  width: 20px;
  height: auto;
}

.navbar_avatar_box > span {
  margin-right: 3px;
}

.credit-nav-box {
  font-size: 12px;
  height: 30px;
}

@media only screen and (min-width: 769px) {
  .navbar {
    padding: 5px 3%;
    width: 80%;
  }

  .navbar_left {
    display: block;
  }

  .nav_icon {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .navbar {
    padding: 5px 2%;
    width: 82%;
    border-bottom: 1px solid #f1f1f1;
  }
}
