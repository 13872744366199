.signInBox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.signInLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 10px;
}

.signInBox .rightSide {
  display: none;
}

.signInBox .leftSide {
  min-height: 100px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
  padding: 40px 35px;
  border-radius: 7px;
  border: 1px solid var(--ash);
}

.sectionTitle {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
}

.signInBox .p-progress-spinner-circle {
  stroke: #ffffff !important;
}

.signInBox .button_spinner .p-progress-spinner-circle {
  stroke: #ffffff !important;
}

@media only screen and (min-width: 769px) {
  .signInBox {
    flex-direction: row;
    row-gap: 0;
  }
  .formBox {
    width: 60%;
  }

  .signInLogo {
    display: none;
  }

  .signInBox .leftSide {
    min-height: 100vh;
    flex: 3;
  }

  .signInBox .rightSide {
    min-height: 100vh;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--secondary);
    flex: 2;
  }

  .signInBox .rightSide .title {
    font-size: 25px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .formBox {
    width: 50%;
  }
}
