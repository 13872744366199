.update-schedule-modal {
  width: 70%;
  z-index: 99999;
}

/* Media Queries for mobile screens */
@media only screen and (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .attendance-dashboard-filter {
    width: 50%;
  }
}

.time-table-period-box {
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
}

.time-table-view {
  width: 100%;
  border-collapse: collapse;
}

.time-table-view th,
.time-table-view td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.time-table-view tr:nth-child(even) {
  background-color: #f2f2f2;
}

.time-table-view tr:hover {
  color: #ffffff;
  background-color: #633ccd;
}

.time-table-day {
  border-bottom: 3px solid var(--primary);
}

.time-table-subject {
  border-bottom: 1px solid #f2f2f2;
  text-align: center;
}

.time-table-subject-title,
.time-table-break,
.time-table-intervals,
.time-table-empty {
  text-align: center !important;
}

.time-table-period-border {
  width: 100%;
  border: 1px dotted #633ccd;
  padding: 15px 20px;
}
