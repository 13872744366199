.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  color: var(--black);
  font-size: 24px;
  overflow: hidden;
}

.not-found-title {
  font-size: 3rem;
  font-family: "comic sans ms";
  font-weight: bold;
}
