.avatar-box {
  position: relative;
  display: flex;
  justify-content: center;
}

.information-container {
  width: 100%;
}

.bio-data-item {
  border-bottom: 1px solid #633ccd57;
  padding-bottom: 10px;
}

@media only screen and (min-width: 769px) {
  .table_button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .category_class_multiselect {
    width: 600px !important;
    max-width: 600px;
  }

  .class_category_multiselect {
    width: 400px !important;
    max-width: 400px;
  }
}
